@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --wui-color-modal-bg-base: #f0efe5 !important;
  --wui-gray-glass-002: #e1dfd4 !important;
}

body {
  font-family: "Inter", sans-serif;
  color: #fff;
  background-color: #f0efe5;
  background-position: center;
}

.App {
  min-height: 100dvh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

@supports not (min-height: 100dvh) {
  .App {
    min-height: 100vh;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.footer {
  padding: 27px;
}

.footer a {
  color: #2b2015;
  font-size: 10px;
  font-weight: 400;
  line-height: 100%; /* 10px */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 8px;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.icon-container {
  margin-top: 2px;
}

.icon-container svg {
  width: 16px;
  height: 16px;
}

.react-toast-container {
  padding: 0px;
}

.Toastify {
  position: absolute;
}

@media (max-width: 768px) {
  .header svg {
    height: 30px;
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    padding: 8px !important;
  }
}

@media (max-width: 400px) {
  .icon-container {
    margin-top: 0;
  }

  .react-toast-container {
    padding: 8px;
  }
}
